import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "grid"
}
const _hoisted_3 = { class: "col-7 text-right pb-1" }
const _hoisted_4 = { class: "col-5 text-right font-bold pb-1" }
const _hoisted_5 = { class: "grid" }
const _hoisted_6 = { class: "col-5 text-right font-bold" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "grid"
}
const _hoisted_9 = { class: "col-5 text-right font-bold pb-1" }
const _hoisted_10 = {
  key: 1,
  class: "grid"
}
const _hoisted_11 = { class: "col-5 text-right font-bold pb-1" }
const _hoisted_12 = { class: "col-7 text-right pb-1" }
const _hoisted_13 = { class: "col-5 text-right font-bold pb-1" }
const _hoisted_14 = {
  key: 2,
  class: "grid"
}
const _hoisted_15 = { class: "col-5 text-right font-bold" }
const _hoisted_16 = {
  key: 3,
  class: "grid"
}
const _hoisted_17 = {
  key: 0,
  class: "col-5 text-right font-bold pb-1"
}
const _hoisted_18 = {
  key: 1,
  class: "col-5 text-right font-bold"
}
const _hoisted_19 = {
  key: 4,
  class: "grid"
}
const _hoisted_20 = { class: "col-5 text-right font-bold" }
const _hoisted_21 = {
  key: 5,
  class: "grid"
}
const _hoisted_22 = { class: "col-5 text-right font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.posOrderCustomSubTotaling)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posOrderSubTotalItems, (field) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: field.dict_name
          }, [
            (
          _ctx.showOrderSubTotal &&
          _ctx.checkSubtotalItemExists(field.pos_order_summary_subtotal)
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(field.pos_order_summary_subtotal_label), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatPrice(_ctx.order[field.pos_order_summary_subtotal])), 1)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "col-7 text-right" }, "Total", -1)),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formatPrice(_ctx.total)), 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        (_ctx.totalParts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "col-7 text-right pb-1" }, "Total Parts", -1)),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.totalParts), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.subTotal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "col-7 text-right pb-1" }, "Sub Total", -1)),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatPrice(_ctx.subTotal)), 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posOrderSummaryFields, (field) => {
          return (_openBlock(), _createElementBlock("div", {
            key: field.dict_name,
            class: "grid"
          }, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(field.label), 1),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.customer[field.json_name]), 1)
          ]))
        }), 128)),
        (_ctx.discountTotal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "col-7 text-right" }, "Discount Applied", -1)),
              _createElementVNode("span", _hoisted_15, "-" + _toDisplayString(_ctx.formatPrice(_ctx.discountTotal)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.showPartsInformation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "col-7 text-right pb-1" }, "Sales Tax", -1)),
              (this.getTaxAmount)
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.formatPrice(_ctx.getTaxAmount)), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_18, "-"))
            ]))
          : _createCommentVNode("", true),
        (_ctx.showPartsInformation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "col-7 text-right" }, "Total", -1)),
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.formatPrice(_ctx.totalWithTax)), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "col-7 text-right" }, "Total", -1)),
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.formatPrice(_ctx.total)), 1)
            ]))
      ]))
}