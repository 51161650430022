import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-input-icon-left p-0 m-0 col-12 flex align-items-center" }
const _hoisted_2 = {
  class: "input-wrapper pb-3",
  style: {"position":"relative","width":"100%","display":"flex","justify-content":"end"}
}
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between","width":"100%","margin-left":"24px","margin-right":"24px"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center","font-size":"x-large"} }
const _hoisted_5 = {
  key: 0,
  class: "pi pi-search",
  style: {"position":"absolute","right":"2rem","top":"38%","transform":"translateY(-50%)"}
}
const _hoisted_6 = {
  key: 0,
  for: "costSwitch",
  class: "ml-4"
}
const _hoisted_7 = { style: {"display":"flex","justify-content":"center","align-items":"center","font-weight":"500","white-space":"nowrap"} }
const _hoisted_8 = {
  class: "part-manufactured-description p-0 m-0",
  style: {"font-weight":"500","white-space":"nowrap"}
}
const _hoisted_9 = { class: "grid p-0 m-0" }
const _hoisted_10 = {
  key: 1,
  class: "pt-4 pb-4"
}
const _hoisted_11 = { class: "w-full" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "text-right pb-0 col-12" }
const _hoisted_15 = {
  key: 0,
  class: "text-right pb-2 col-12",
  style: { color: '#6c757d', fontSize: 'larger' }
}
const _hoisted_16 = { class: "flex flex-row items-center" }
const _hoisted_17 = { style: {"position":"relative"} }
const _hoisted_18 = {
  key: 0,
  class: "text-right",
  style: {
                color: '#6c757d',
                fontWeight: 'bold',
                right: '9px',
                bottom: '-20px',
                position: 'absolute',
              }
}
const _hoisted_19 = {
  class: "ml-2",
  style: {
              display: 'flex',
              alignItems: 'center',
              fontWeight: '500',
            }
}
const _hoisted_20 = { style: {"display":"flex","flex-direction":"row","justify-content":"end"} }
const _hoisted_21 = {
  key: 1,
  class: "p-row-editor-save m-1",
  style: {"width":"2rem"}
}
const _hoisted_22 = { class: "flex-container" }
const _hoisted_23 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_RoverInput = _resolveComponent("RoverInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_LookupPartNumber = _resolveComponent("LookupPartNumber")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      ref: "partsTableRef",
      value: _ctx.lineItems,
      expandedRows: _ctx.expandedRows,
      "onUpdate:expandedRows": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.expandedRows) = $event)),
      filters: _ctx.filters,
      editMode: _ctx.editMode,
      scrollable: true,
      scrollHeight: _ctx.scrollHeight,
      class: _normalizeClass(_ctx.getDataTableClass),
      dataKey: "lis",
      globalFilterFields: ['li_parts', 'wrap_desc'],
      responsiveLayout: "stack",
      stripedRows: ""
    }, {
      empty: _withCtx(() => _cache[10] || (_cache[10] = [
        _createElementVNode("div", { class: "p-text-center" }, [
          _createElementVNode("span", { class: "p-text-bold" }, "No Items Found")
        ], -1)
      ])),
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.tableTitle), 1),
              (_ctx.selectedLineItemIndex === -1)
                ? (_openBlock(), _createBlock(_component_InputText, {
                    key: 0,
                    modelValue: _ctx.filters['global'].value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                    placeholder: "Keyword Search",
                    class: "m-0",
                    style: {"padding-right":"2rem"}
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.selectedLineItemIndex === -1)
              ? (_openBlock(), _createElementBlock("i", _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          (_ctx.getPosShowPartCostColumn)
            ? (_openBlock(), _createElementBlock("label", _hoisted_6, "Show Cost"))
            : _createCommentVNode("", true),
          (_ctx.getPosShowPartCostColumn)
            ? (_openBlock(), _createBlock(_component_InputSwitch, {
                key: 1,
                modelValue: _ctx.toggleShowCostColumn,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toggleShowCostColumn) = $event)),
                id: "costSwitch",
                class: "ml-1 mr-4"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ])
      ]),
      expansion: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_22, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEditableLineItemFields.slice(2), (field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: field.field_no,
              class: "flex-item"
            }, [
              _createElementVNode("label", {
                for: field.json_name,
                class: "custom-label"
              }, _toDisplayString(field.display_name), 9, _hoisted_23),
              _createVNode(_component_RoverInput, {
                field: field,
                modelValue: slotProps.data.custom_fields[field.json_name],
                "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (
              _ctx.setFieldLineItemData($event, slotProps.data, field)
            )]
              }, null, 8, ["field", "modelValue", "onUpdate:modelValue"])
            ]))
          }), 128)),
          _createVNode(_component_Textarea, {
            modelValue: slotProps.data.li_notes,
            "onUpdate:modelValue": ($event: any) => ((slotProps.data.li_notes) = $event),
            placeholder: "Enter line item notes here...",
            rows: "3",
            class: "mb-1 mt-2 w-full"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          expander: "",
          style: {"width":"2rem"},
          "body-style": "max-width: 2rem;"
        }),
        _createVNode(_component_Column, {
          header: "Part Number",
          class: "tag-column",
          field: "onHand",
          style: {"width":"3rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, _toDisplayString(data.li_parts) + " ", 1),
              _createElementVNode("span", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.controlFieldValues('PARTS', '0'), (field) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: field.field_no
                  }, " | " + _toDisplayString(field.label) + " " + _toDisplayString(data[field.json_name]), 1))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "part_number",
          editable: "",
          header: "Description",
          "body-style": "min-width: 12rem;"
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_9, [
              (!_ctx.getDisableUpdatingPartDescription)
                ? (_openBlock(), _createBlock(_component_Textarea, {
                    key: 0,
                    ref: 'descriptionInput' + slotProps.index,
                    modelValue: slotProps.data.wrap_desc,
                    "onUpdate:modelValue": ($event: any) => ((slotProps.data.wrap_desc) = $event),
                    placeholder: "Enter line item description here...",
                    class: "mb-1 w-full",
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => ($event.target.select())),
                    rows: "2"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(slotProps.data.wrap_desc), 1)
                  ]))
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          class: "tag-column",
          field: "onHand",
          style: {"width":"2rem"}
        }, {
          body: _withCtx(({ data }) => [
            (data.li_parts !== '')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.showPartInventory($event, data)),
                  style: {"cursor":"pointer","display":"flex","justify-content":"center","align-items":"center"}
                }, [
                  _withDirectives(_createVNode(_component_Tag, {
                    severity: 
              _ctx.handleOnHandTagColor(
                data.avail_qty !== undefined ? data.avail_qty : 0,
              )
            ,
                    value: 
              _ctx.handleOnHandTagText(
                data.avail_qty !== undefined ? data.avail_qty : 0,
              )
            ,
                    rounded: "",
                    class: "text-center on-hand-tag"
                  }, null, 8, ["severity", "value"]), [
                    [_directive_tooltip, 'Click to view inventory']
                  ])
                ], 8, _hoisted_12))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.getPosShowPartCostColumn && _ctx.toggleShowCostColumn)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              class: "blue-text font-bold code-price-wrapper",
              field: "average_cost",
              header: "Cost"
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatPrice(slotProps.data.average_cost)), 1),
                  (slotProps.data.li_order_qtys > 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.formatPrice(
                slotProps.data.li_order_qtys *
                  parseFloat(slotProps.data.average_cost),
              )), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          class: "code-price-wrapper",
          field: "li_prices",
          header: "Price",
          "body-style": "min-width: 10rem;",
          style: {"width":"7rem"}
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_InputNumber, {
                  modelValue: slotProps.data.li_prices,
                  "onUpdate:modelValue": ($event: any) => ((slotProps.data.li_prices) = $event),
                  style: { color: '#6c757d', fontSize: 'larger' },
                  inputProps: { inputmode: 'decimal' },
                  minFractionDigits: 2,
                  maxFractionDigits: 2,
                  min: _ctx.minimumPrice,
                  prefix: "$",
                  onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select()))
                }, null, 8, ["modelValue", "onUpdate:modelValue", "min"]),
                (
                _ctx.editable &&
                slotProps.data.li_prices &&
                slotProps.data.li_order_qtys > 1
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.formatPrice(
                  slotProps.data.li_order_qtys *
                    parseFloat(slotProps.data.li_prices),
                )), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.priceUnitOfMeasureSuffix(slotProps.data.um)), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "li_order_qtys",
          class: "quantity-wrapper",
          header: "Quantity",
          style: {"width":"8rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", {
              onClick: _cache[5] || (_cache[5] = ($event) => $event.stopPropagation()),
              style: {"display":"flex","justify-content":"center","align-items":"center"}
            }, [
              _createVNode(_component_InputNumber, {
                ref: "inputNumber",
                class: "quantity-input border-round-md",
                modelValue: data.li_order_qtys,
                "onUpdate:modelValue": ($event: any) => ((data.li_order_qtys) = $event),
                min: _ctx.useFractionalQuantities(data) ? 0 : _ctx.calculateStep(data),
                inputProps: { inputmode: 'numeric' },
                inputStyle: { minWidth: '60px !important' },
                showButtons: "",
                buttonLayout: "horizontal",
                incrementButtonIcon: "pi pi-plus",
                decrementButtonIcon: "pi pi-minus",
                step: _ctx.calculateStep(data),
                onFocus: _cache[4] || (_cache[4] = ($event: any) => ($event.target.select())),
                inputId: _ctx.getQuantityInputId(data),
                minFractionDigits: _ctx.useFractionalQuantities(data) ? 1 : undefined,
                onInput: ($event: any) => (_ctx.handleDecimals($event, data)),
                onBlur: ($event: any) => (_ctx.validatePartQuantity($event, data))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "min", "step", "inputId", "minFractionDigits", "onInput", "onBlur"])
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEditableLineItemFields.slice(0, 2), (field) => {
            return (_openBlock(), _createBlock(_component_Column, {
              field: field.json_name,
              header: field.display_name,
              key: field.field_no,
              style: {"width":"8rem"}
            }, {
              body: _withCtx((slotProps) => [
                _createVNode(_component_RoverInput, {
                  field: field,
                  inputStyle: "width: 8rem",
                  modelValue: slotProps.data.custom_fields[field.json_name],
                  "onUpdate:modelValue": [($event: any) => ((slotProps.data.custom_fields[field.json_name]) = $event), ($event: any) => (
              _ctx.setFieldLineItemData($event, slotProps.data, field)
            )]
                }, null, 8, ["field", "modelValue", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["field", "header"]))
          }), 128))
        ]),
        _createVNode(_component_Column, {
          class: "editor-column",
          rowEditor: true,
          style: {"width":"4rem"}
        }, {
          body: _withCtx((rowData) => [
            _createElementVNode("div", _hoisted_20, [
              (_ctx.shouldShowSaveAndResetButtons(rowData.data))
                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    icon: "pi pi-undo",
                    class: "p-row-editor-cancel m-1",
                    onClick: ($event: any) => (_ctx.handleCancelEdit(rowData))
                  }, null, 8, ["onClick"])), [
                    [_directive_tooltip, 'Reset Changes']
                  ])
                : (_openBlock(), _createElementBlock("div", _hoisted_21)),
              _withDirectives(_createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-row-editor-init m-1",
                style: {"color":"red"},
                onClick: ($event: any) => (_ctx.removePart($event, rowData.index))
              }, null, 8, ["onClick"]), [
                [_directive_tooltip, 'Delete part']
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "expandedRows", "filters", "editMode", "scrollHeight", "class"]),
    _createVNode(_component_LookupPartNumber, {
      partNumber: _ctx.selectedPart,
      show: _ctx.showLookupPartNumberDialog,
      onOnClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showLookupPartNumberDialog = false))
    }, null, 8, ["partNumber", "show"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showRemoveConfirm,
      header: "Confirm Remove",
      icon: "WARNING",
      message: _ctx.removeConfirmMessage(_ctx.indexToRemove),
      primaryButton: "Remove",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.removeEditPart(_ctx.indexToRemove))),
      onCancelButtonClick: _cache[9] || (_cache[9] = ($event: any) => {
      _ctx.showRemoveConfirm = false;
      _ctx.indexToRemove = -1;
    })
    }, null, 8, ["showDialog", "message"])
  ], 64))
}