
import { defineComponent, inject } from "vue";
import { mapActions, mapGetters } from "vuex";
import Utils from "@/utility/utils";

export default defineComponent({
  inject: ["customer"],
  setup() {
    const customer = inject<any>("customer");
    return { customer };
  },
  methods: {
    ...mapActions({
      removeFromOrder: "pos/removeFromOrder",
    }),
    checkSubtotalItemExists(field: string): boolean {
      const modifiedField = field.replace(/_/g, ".").toUpperCase();

      return (
        this.correlativeExists("SO", modifiedField) ||
        this.fieldExists("SO", modifiedField) ||
        this.correlativeExists("SO", field) ||
        this.fieldExists("SO", field)
      );
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
  },
  computed: {
    ...mapGetters({
      posOrderCustomSubTotaling: "mrkControl/posOrderCustomSubTotaling",
      posOrderSubTotalItems: "mrkControl/posOrderSubTotalItems",
      correlativeExists: "fdict/correlativeExists",
      fieldExists: "fdict/fieldExists",
      order: "pos/order",
      getOrder: "pos/getCurrentOrder",
      getCurrentActivity: "pos/getCurrentActivity",
      getTaxAmount: "pos/getTaxAmount",
      getPosAutoValidate: "mrkControl/posAutoValidate",
      posOrderSummaryItems: "mrkControl/posOrderSummaryItems",
      customFDictFields: "fdict/customFields",
    }),
    showOrderAmount(): boolean {
      return this.correlativeExists("SO", "ORDER.AMOUNT");
    },
    showOrderInformation(): boolean {
      return this.getCurrentActivity === "ORDERS";
    },
    showInvoiceInformation(): boolean {
      return this.getCurrentActivity === "INVOICES";
    },
    totalWithTax(): string {
      if (this.showPartsInformation) {
        let sum = parseFloat(this.total) + (parseFloat(this.getTaxAmount) || 0);
        return sum.toFixed(2);
      } else {
        let sum = parseFloat(this.total) + (parseFloat(this.getTaxAmount) || 0);
        return sum.toFixed(2);
      }
    },
    total(): string {
      if (
        this.getPosAutoValidate &&
        this.showOrderAmount &&
        this.showOrderSubTotal
      ) {
        return this.order.order_amount;
      } else {
        let sum = 0;
        if (this.showPartsInformation) {
          if (!this.order.lis_items || this.order.lis_items.length === 0) {
            return sum.toFixed(2);
          }
          this.order?.lis_items?.forEach((lineItem: any) => {
            const quantity = parseFloat(lineItem.li_order_qtys);
            const price = parseFloat(lineItem.li_prices) || 0;
            const discount = parseFloat(lineItem.li_discs || "0") / 100;
            sum += (price - price * discount) * quantity || 0;
          });
        } else if (this.showInvoiceInformation) {
          this.getOrder.forEach((part: any) => {
            sum += parseFloat(part.balance) || 0;
          });
        } else if (this.showOrderInformation) {
          this.getOrder.forEach((part: any) => {
            sum += parseFloat(part.order_amount) || 0;
          });
        }
        return sum.toFixed(2);
      }
    },
    subTotal() {
      if (this.showPartsInformation) {
        let sum = 0;

        if (!this.order.lis_items || this.order.lis_items.length === 0) {
          return sum.toFixed(2);
        }

        this.order?.lis_items?.forEach((lineItem: any) => {
          sum +=
            parseFloat(lineItem.li_order_qtys) *
              parseFloat(lineItem.li_prices) || 0;
        });
        return sum.toFixed(2);
      } else {
        let sum = 0;
        this.getOrder.forEach((part: any) => {
          const quantity = part.quantity > 1 ? part.quantity : 1;
          const price = parseFloat(part.price) || 0;
          sum += price * quantity || 0;
          sum += parseFloat(part.order_amount) || 0;
          sum += parseFloat(part.balance) || 0;
        });
        return sum.toFixed(2);
      }
    },
    showPartsInformation(): boolean {
      return this.getCurrentActivity === "PARTS";
    },
    totalParts(): number {
      let total = 0;
      if (this.showPartsInformation) {
        this.order?.lis_items?.forEach((lineItem: any) => {
          total += parseFloat(lineItem.li_order_qtys) || 0;
        });
      }
      return total;
    },
    showOrderSubTotal(): boolean {
      return (
        this.$route.fullPath === "/pos/parts" ||
        this.$route.fullPath === "/pos/shipping"
      );
    },
    posOrderSummaryFields(): any[] {
      const summaryItemIds = this.posOrderSummaryItems.map(
        (item: any) => item.pos_order_summary_id,
      );
      const fields = this.customFDictFields("CUST")
        .filter((item: any) => summaryItemIds.includes(item.field_no))
        .map((item: any) => {
          return {
            ...item,
            label: item.desc_items.map((desc: any) => desc.desc).join(" "),
            json_name: item.json_name,
          };
        });

      return fields;
    },
    discountTotal(): string {
      let sum = 0;
      if (this.showPartsInformation) {
        let discountTotal = 0;
        this.order?.lis_items?.forEach((lineItem: any) => {
          const quantity = parseFloat(lineItem.li_order_qtys);
          const price = parseFloat(lineItem.li_prices) || 0;
          const discount = parseFloat(lineItem.li_discs || "0") / 100;
          discountTotal += price * discount * quantity || 0;
        });

        if (discountTotal) {
          return discountTotal.toFixed(2);
        } else {
          return "";
        }
      } else {
        const disc = parseFloat(this.customer?.disc) / 100 || 0.0;
        this.order.forEach((part: any) => {
          const quantity = part.quantity > 1 ? part.quantity : 1;
          const price = parseFloat(part.price) || 0;
          sum += price * disc * quantity || 0;
        });

        if (sum) {
          return sum.toFixed(2);
        } else {
          return "";
        }
      }
    },
  },
  props: {
    order: {
      required: true,
    },
  },
});
