
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import CheckBox from "primevue/checkbox";

export default defineComponent({
  name: "PrinterSelectionDialog",
  components: {
    Dialog,
    Dropdown,
    CheckBox,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    printers: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultPrinter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDialog: this.visible,
      selectedPrinter: null as any | null,
      saveDefaultPrinter: false,
      dataLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      getPrinterQueues: "printControl/getPrinterQueues",
      getClient: "session/getClient",
    }),
    printerOptions() {
      // Use printers prop if not empty, otherwise use server printers
      return this.printers.length > 0
        ? this.printers
        : this.getPrinterQueues.serverPrinters;
    },
    dropdownPlaceholder() {
      return this.dataLoaded ? "Select Printer" : "Loading Printers...";
    },
  },
  emits: ["onPrintDialogPrint", "onPrintDialogCancel"],

  methods: {
    ...mapActions({
      getPrinterControl: "printControl/getPrintControl",
    }),
    handlePrint() {
      this.$emit("onPrintDialogPrint", {
        selectedPrinter: this.selectedPrinter,
        saveDefaultPrinter: this.saveDefaultPrinter,
      });
      this.showDialog = false;
    },

    cancel() {
      this.showDialog = false;
      this.$emit("onPrintDialogCancel");
    },
  },
  async created() {
    this.showDialog = this.visible;
    this.selectedPrinter = null;
    await this.getPrinterControl({ client: this.getClient, refresh: true });
    this.dataLoaded = true;
  },
  updated() {
    if (this.defaultPrinter) {
      this.selectedPrinter = this.defaultPrinter;
    }
    this.showDialog = this.visible;
  },
});
