import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-col-12 text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _component_PrinterSelectionDialog = _resolveComponent("PrinterSelectionDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: _ctx.records,
      dataKey: "ar_id",
      paginator: true,
      filterDisplay: "menu",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
      rows: _ctx.rowsPerPage,
      loading: _ctx.isLoading,
      "onUpdate:rows": _cache[2] || (_cache[2] = ($event: any) => (_ctx.rowsPerPage = $event)),
      onPage: _ctx.onPage,
      rowClass: _ctx.isSelectedRow,
      rowsPerPageOptions: [5, 10, 25],
      selectionMode: "single",
      onRowSelect: _ctx.onRowSelect,
      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} Items",
      class: "p-datatable-sm custom-date-range-table",
      breakpoint: "700px",
      responsiveLayout: "stack",
      removableSort: ""
    }, _createSlots({
      empty: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(`${_ctx.textBaseClass} fond-bold`)
          }, _toDisplayString(_ctx.emptyTableLabel), 3)
        ])
      ]),
      default: _withCtx(() => [
        (!_ctx.custId)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              field: "cust_name",
              header: "Customer",
              headerClass: `${_ctx.tableColumnHeaderClass} pl-5`,
              class: _normalizeClass(_ctx.textBaseClass),
              bodyClass: "font-semibold text-center w-3",
              sortable: true,
              showFilterMatchModes: false,
              showFilterOperator: false
            }, {
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  class: "p-column-filter",
                  placeholder: "Search by Id"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["headerClass", "class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "ar_id",
          header: "Invoice",
          headerClass: _ctx.tableColumnHeaderClass,
          class: _normalizeClass(_ctx.textBaseClass),
          bodyClass: "font-normal text-center w-1",
          sortable: true,
          showFilterMatchModes: false,
          showFilterOperator: false,
          style: _normalizeStyle({ minWidth: `${!_ctx.compact ? '120px' : ''}` })
        }, {
          filter: _withCtx(({ filterModel }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              class: "p-column-filter",
              placeholder: "Search by Id"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 8, ["headerClass", "class", "style"]),
        _createVNode(_component_Column, {
          field: "invoice_date",
          header: "Invoice Date",
          headerClass: _ctx.tableColumnHeaderClass,
          class: _normalizeClass(_ctx.textBaseClass),
          sortable: true,
          bodyClass: "font-normal text-center w-1",
          style: _normalizeStyle({ minWidth: `${!_ctx.compact ? '200px' : '100px'}` })
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.formatStringDate(data.invoice_date)), 1)
          ]),
          _: 1
        }, 8, ["headerClass", "class", "style"]),
        _createVNode(_component_Column, {
          field: "due_date",
          header: "Due Date",
          headerClass: _ctx.tableColumnHeaderClass,
          class: _normalizeClass(_ctx.textBaseClass),
          sortable: true,
          bodyClass: "font-normal text-center w-1",
          style: _normalizeStyle({ minWidth: `${!_ctx.compact ? '110px' : '90px'}` })
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.formatStringDate(data.due_date)), 1)
          ]),
          _: 1
        }, 8, ["headerClass", "class", "style"]),
        (_ctx.showType)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 1,
              field: "type",
              header: "Type",
              headerClass: _ctx.tableColumnHeaderClass,
              sortable: "",
              showFilterMatchModes: false,
              showFilterOperator: false,
              "body-class": "text-center"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.getTypeTitle(data.type)), 1)
              ]),
              filter: _withCtx(({ filterModel }) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  options: _ctx.types,
                  optionLabel: "name",
                  optionValue: "code",
                  placeholder: "Filter By Type",
                  style: { width: '150px' }
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
              ]),
              filterclear: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Clear",
                  onClick: ($event: any) => {
            filterCallback();
            _ctx.handleClearFilterByType();
          }
                }, null, 8, ["onClick"])
              ]),
              filterapply: _withCtx(({ filterCallback }) => [
                _createVNode(_component_Button, {
                  type: "button",
                  label: "Search",
                  onClick: ($event: any) => {
            filterCallback();
            _ctx.handleFilter();
          }
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["headerClass"]))
          : _createCommentVNode("", true),
        (_ctx.showInvoiceAmountColumn)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 2,
              field: "invoice_amt",
              header: "Amount",
              headerClass: _ctx.tableColumnHeaderClass,
              class: _normalizeClass(_ctx.textBaseClass),
              sortable: true,
              bodyClass: _ctx.tableAmountColumnBodyClass
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.currencyClass)
                }, _toDisplayString(_ctx.formatCurrency(data.invoice_amt ?? "")), 3)
              ]),
              _: 1
            }, 8, ["headerClass", "class", "bodyClass"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "balance",
          header: "Balance",
          headerClass: _ctx.tableColumnHeaderClass,
          class: _normalizeClass(_ctx.textBaseClass),
          sortable: true,
          bodyClass: _ctx.tableAmountColumnBodyClass
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.currencyClass)
            }, _toDisplayString(_ctx.formatCurrency(data.balance ?? "")), 3)
          ]),
          _: 1
        }, 8, ["headerClass", "class", "bodyClass"]),
        _createVNode(_component_Column, {
          field: "status",
          header: "Status",
          headerClass: _ctx.tableColumnHeaderClass,
          sortable: "",
          showFilterMatchModes: false,
          showFilterOperator: false,
          bodyClass: "font-semibold text-center w-1"
        }, {
          body: _withCtx(({ data }) => [
            (data.ach_pending === 'Y')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_Badge, {
                    value: "PAY PENDING",
                    severity: "info"
                  })
                ]))
              : (data.status === 'O')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_Badge, {
                      value: "OUTSTANDING",
                      severity: "danger"
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_Badge, {
                      value: "PAID",
                      severity: "success"
                    })
                  ]))
          ]),
          filter: _withCtx(({ filterModel }) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              options: _ctx.stati,
              optionLabel: "name",
              optionValue: "code",
              placeholder: "Filter By Type",
              style: { width: '170px' }
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          filterclear: _withCtx(({ filterCallback }) => [
            _createVNode(_component_Button, {
              type: "button",
              label: "Clear",
              onClick: ($event: any) => {
            filterCallback();
            _ctx.handleClearFilterByStatus();
          }
            }, null, 8, ["onClick"])
          ]),
          filterapply: _withCtx(({ filterCallback }) => [
            _createVNode(_component_Button, {
              type: "button",
              label: "Search",
              onClick: ($event: any) => {
            filterCallback();
            _ctx.handleFilter();
          }
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["headerClass"]),
        (!_ctx.hidePoNo)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 3,
              field: "po",
              header: "PO Number",
              headerClass: _ctx.tableColumnHeaderClass,
              class: _normalizeClass(_ctx.textBaseClass),
              bodyClass: "font-normal text-center w-1"
            }, null, 8, ["headerClass", "class"]))
          : _createCommentVNode("", true),
        (_ctx.showToPay && _ctx.getAccess('/cash'))
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 4,
              field: "",
              header: "To Pay",
              headerClass: _ctx.tableColumnHeaderClass,
              class: _normalizeClass(_ctx.textBaseClass),
              bodyClass: "font-normal text-center w-1"
            }, {
              body: _withCtx(({ data }) => [
                (data.status === 'O' && data.ach_pending !== 'Y')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      (_ctx.existsInPayArray(data))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            (!_ctx.hover[data.ar_id])
                              ? (_openBlock(), _createBlock(_component_Button, {
                                  key: 0,
                                  value: { arId: data.ar_id, balance: data.balance },
                                  icon: "pi pi-check",
                                  iconPos: "right",
                                  class: "receivables-pay-btn",
                                  label: "Selected",
                                  onMouseover: 
                () => {
                  _ctx.hover[data.ar_id] = true;
                }
              ,
                                  onMouseleave: ($event: any) => (_ctx.hover[data.ar_id] = false)
                                }, null, 8, ["value", "onMouseover", "onMouseleave"]))
                              : _createCommentVNode("", true),
                            (_ctx.hover[data.ar_id])
                              ? (_openBlock(), _createBlock(_component_Button, {
                                  key: 1,
                                  value: { arId: data.ar_id, balance: data.balance },
                                  onClick: ($event: any) => (_ctx.removeInvoice(data)),
                                  icon: "pi pi-times",
                                  iconPos: "right",
                                  class: "receivables-pay-btn",
                                  label: "Remove",
                                  onMouseleave: ($event: any) => (_ctx.hover[data.ar_id] = false),
                                  onMouseover: ($event: any) => (_ctx.hover[data.ar_id] = true)
                                }, null, 8, ["value", "onClick", "onMouseleave", "onMouseover"]))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                            _createVNode(_component_Button, {
                              class: "text-sm px-2 pay-now-btn",
                              onClick: ($event: any) => (_ctx.addInvoiceToPay(data))
                            }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createElementVNode("div", { class: "icons-section" }, [
                                  _createElementVNode("div", { class: "icon-container top" }, [
                                    _createElementVNode("i", { class: "pi pi-dollar border-circle icon-circle" })
                                  ]),
                                  _createElementVNode("div", { class: "icon-container" }, [
                                    _createElementVNode("i", { class: "pi pi-dollar border-circle icon-circle" })
                                  ])
                                ], -1),
                                _createElementVNode("span", { class: "text-sm pl-2" }, "Pay now", -1)
                              ])),
                              _: 2
                            }, 1032, ["onClick"])
                          ]))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["headerClass", "class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          headerClass: _ctx.tableColumnHeaderClass,
          class: _normalizeClass(_ctx.textBaseClass),
          bodyClass: "text-center w-1"
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", null, [
              _createVNode(_component_Button, {
                class: "pi pi-plus p-button-icon-only p-speeddial-button p-button-rounded",
                style: {"height":"35px","width":"35px"},
                onClick: ($event: any) => (_ctx.openMenu($event, data))
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["headerClass", "class"]),
        _createVNode(_component_ColumnGroup, { type: "footer" }, {
          default: _withCtx(() => [
            _createVNode(_component_Row, { class: "totals" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  footer: 'Totals: ',
                  class: "text-center font-bold",
                  footerStyle: "text-align:center"
                }),
                _createVNode(_component_Column, {
                  footer: _ctx.records.length,
                  class: "font-bold text-center w-1"
                }, null, 8, ["footer"]),
                _createVNode(_component_Column, {
                  footer: "",
                  colspan: _ctx.columnCount - 5,
                  class: "text-right font-bold",
                  footerStyle: "text-align:right"
                }, null, 8, ["colspan"]),
                _createVNode(_component_Column, {
                  footer: _ctx.getTotalBalance,
                  class: "text-right font-bold",
                  footerStyle: "text-align:right"
                }, null, 8, ["footer"]),
                _createVNode(_component_Column, {
                  class: "text-right font-bold",
                  colspan: 3,
                  footerStyle: "text-align:right"
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 2
    }, [
      (_ctx.tableName)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-wrap align-items-center justify-content-between p-0 m-0", { 'p-1 m-0': !_ctx.isCustomTab }])
              }, [
                _createElementVNode("h3", {
                  class: _normalizeClass(["text-center flex p-0 m-0 pl-3", {
            'text-start w-full p-0 m-0 pl-3': !_ctx.isCustomTab,
            'text-center flex p-0 m-0 pl-3': _ctx.isCustomTab,
          }])
                }, _toDisplayString(_ctx.tableName), 3),
                (_ctx.isCustomTab)
                  ? (_openBlock(), _createBlock(_component_Calendar, {
                      key: 0,
                      modelValue: _ctx.dateRange,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
                        _ctx.handleHideCalendar
                      ],
                      format: "MM/dd/yyyy",
                      selectionMode: "range",
                      manualInput: false,
                      placeholder: "Select Date Range",
                      showButtonBar: true,
                      showIcon: true,
                      class: "h-2rem"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : _createCommentVNode("", true)
              ], 2)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["value", "filters", "rows", "loading", "onPage", "rowClass", "onRowSelect"]),
    _createVNode(_component_ContextMenu, {
      ref: "menu",
      model: _ctx.contextItems
    }, null, 8, ["model"]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailInvoiceDialog,
      header: 'Email Invoice ' + _ctx.currentInvoiceId,
      onHide: _cache[3] || (_cache[3] = 
      (isHidden) => {
        _ctx.showEmailInvoiceDialog = isHidden;
      }
    ),
      fileId: _ctx.currentInvoiceId,
      fileName: _ctx.currentInvoiceId,
      onOnEmail: _ctx.sendEmail
    }, null, 8, ["show", "header", "fileId", "fileName", "onOnEmail"]),
    _createVNode(_component_PrinterSelectionDialog, {
      visible: _ctx.showHostInvoicePrintDialog,
      heading: "Select Invoice Printer",
      defaultPrinter: _ctx.getDefaultPickTicketPrinter,
      onOnPrintDialogPrint: _ctx.handlePrintInvoice,
      onOnPrintDialogCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showHostInvoicePrintDialog = false))
    }, null, 8, ["visible", "defaultPrinter", "onOnPrintDialogPrint"])
  ], 64))
}