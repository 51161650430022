
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import SalesOrderService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";
import ShipService from "@/services/ShipService";
import Utils from "@/utility/utils";
import Dialog from "primevue/dialog";
import PrintPickTicket from "@/components/Pos/PrintPickTicket.vue";

const salesOrderService = new SalesOrderService();
const custService = new CustomerService();
const shipService = new ShipService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "OrderConfirmation",
  components: {
    Button,
    EmailFileDialog,
    Dialog,
    InputText,
    PrintPickTicket,
  },
  data() {
    return {
      custId: "",
      isPDFLoading: false,
      showEmailPDFDialog: false,
      showPickTicketPrinterDialog: false,
      email: "",
      showCfee: false,
      loading: false,
      loadingShipOrder: false,
      saveDefaultPickTicketPrinter: false,
      payNote: "",
      showNoteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getLastOrderId: "pos/getLastOrderId",
      getLastOrderCustomerContacts: "pos/getLastOrderCustomerContacts",
      getStoredCartItems: "pos/getStoredCartItems",
      getCustomer: "pos/getCustomer",
      getLoadingPDFs: "sales/getLoadingPDFs",
      getClient: "session/getClient",
      postInvoiceOnShip: "mrkControl/postInvoiceOnShip",
      hideOrderButtons: "mrkControl/hideOrderButtons",
      showPickTicketPrint: "mrkControl/showPickTicketPrint",
      getUser: "session/getUser",
      getDefaultPickTicketPrinter: "pos/getDefaultPickTicketPrinter",
      getWebAccess: "session/getWebAccess",
      getLastOrder: "pos/getLastOrder",
    }),
    showShipOrderButton(): boolean {
      return this.getWebAccess("SHIP.E");
    },
  },
  async created() {
    if (this.getCustomer) {
      this.custId = this.getCustomer.cust_id;
    }
    if (!this.getLastOrderId) {
      this.$router.push("/pos");
    }
  },
  methods: {
    ...mapActions({
      setStoredCartItems: "pos/setStoredCartItems",
      replaceOrder: "pos/replaceOrder",
      getOrderPDF: "sales/getOrderPDF",
      addNotification: "notification/add",
      clearCust: "pos/clearCustomer",
      setLastOrderCustomerContacts: "pos/setLastOrderCustomerContacts",
      fetchControls: "control/fetchControl",
      setDefaultPickTicketPrinter: "pos/setDefaultPickTicketPrinter",
      clearTenders: "pos/clearTenders",
      setCurrentActivity: "pos/setCurrentActivity",
    }),
    addPayNote() {
      let po_items = [] as any;
      const po_item = this.getStoredCartItems.orderInvoice[0];
      po_items.push({
        ar_id: po_item.ar_id,
        amount: parseFloat(po_item.balance).toFixed(2).toString(),
        type: "PO",
        balance: parseFloat(po_item.balance).toFixed(2).toString(),
        pay_note: this.payNote,
      });
      this.replaceOrder(po_items);
      this.showNoteModal = false;
      this.payNote = "";
      this.$router.push("/pos/payment");
    },
    confirmCfee() {
      return new Promise((resolve) => {
        this.$confirm.require({
          message:
            "Please verify customer is aware they may incur a 2.5% credit card fee \n Do not proceed without verbal commitment.",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.handleShipOrder(this.getLastOrderId, "accept");
            this.showCfee = false;
            resolve(true);
          },
          reject: () => {
            this.showCfee = false;
            this.addNotification({
              message: `Credit Card Fee has not been accepted. Order # ${this.getLastOrderId} has not been finalized`,
              type: "error",
            });
            resolve(false);
          },
        });
      });
    },
    handleContinueShopping() {
      this.$router.push("/pos");
      this.$emit("onShowKeepCustomerDialog");
    },
    handleShipOrder(orderId: string, cfee?: string) {
      this.setStoredCartItems({
        items: [],
        type: "orderInvoice",
      });
      this.loadingShipOrder = true;
      let request;
      if (cfee) {
        request = { so_id: orderId, ship_id: orderId, fmp_cfee: cfee };
      } else {
        request = { so_id: orderId, ship_id: orderId };
      }
      shipService
        .postShip(request)
        .then(async (response: any) => {
          if (response.status === "CFEE") {
            //display prompt
            this.showCfee = true;
            return;
          } else if (response.status === "success") {
            this.addNotification({
              message: `Order #${orderId} has been finalized`,
              type: "success",
            });
            //search invoice utils

            this.clearTenders();
            const list = JSON.parse(
              JSON.stringify(this.getStoredCartItems.summaryOrder),
            );
            const updatedList = list.filter(function (el: any) {
              return el.so_id !== orderId;
            });
            this.setStoredCartItems({
              items: JSON.parse(JSON.stringify(updatedList || [])),
              type: "summaryOrder",
            });
            this.replaceOrder(this.getStoredCartItems.summaryOrder);

            await Utils.selectOrderInvoice(
              response,
              () => {
                this.loadingShipOrder = false;
              },
              () => {
                this.handleContinueShopping();
              },
            ).then(() => {
              if (
                this.getStoredCartItems.orderInvoice &&
                this.getStoredCartItems.orderInvoice?.length > 0
              ) {
                this.setCurrentActivity("INVOICES");
                this.replaceOrder(this.getStoredCartItems.orderInvoice || []);
                // route to payment
                //if it's a payout, add paynote
                if (this.getStoredCartItems.orderInvoice[0].type === "PO") {
                  this.showNoteModal = true;
                } else {
                  this.$router.push("/pos/payment");
                }
              }
            });
          } else {
            this.addNotification({
              message: `Failed to Finalize Order # ${orderId}. ${response.error}`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Failed to Finalize Order # ${orderId}. ${error.response.data.error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loadingShipOrder = false;
        });
    },
    async handleGetOrderPDF(orderId: string) {
      this.isPDFLoading = true;
      await this.getOrderPDF({ recordId: orderId });
      this.isPDFLoading = false;
    },
    orderPDFIcon(id: string) {
      let downloading: boolean =
        this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      return {
        "pi pi-download col-12": !downloading,
        "pi pi-spin pi-spinner col-12": downloading,
      };
    },
    handleEmailPDF() {
      this.showEmailPDFDialog = true;
    },
    handleCloseEmailDialog() {
      this.showEmailPDFDialog = false;
      this.email = "";
    },
    sendEmail(data: any) {
      salesOrderService
        .getOrderPDF(this.getLastOrderId, this.getClient, data)
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Sales Order #${this.getLastOrderId} has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Sales Order was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Sales Order could not be sent: ${error}`,
            type: "error",
          });
        });
    },
    async handlePickTicketPrinterSelection() {
      // set the default printer for Imacs implementation
      this.showPickTicketPrinterDialog = true;
    },
    handleAddressAdded() {
      custService
        .getCustomer(this.custId, this.getClient, "contact_email contact_name")
        .then((response: any) => {
          this.setLastOrderCustomerContacts(response.contact_id_items);
        });
    },
  },
});
