
import { defineComponent } from "vue";
import Button from "primevue/button";
import Card from "primevue/card";
import SendReceiptDialog from "@/components/Pos/SendReceiptDialog.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import PaymentService from "@/services/PaymentService";
import LocalDesktopService from "@/services/LocalDesktopService";
import Utils from "@/utility/utils";
import PrinterSelectionDialog from "@/components/Pos/PrinterSelectionDialog.vue";
const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const localDesktopService = new LocalDesktopService();

export default defineComponent({
  components: {
    Button,
    Card,
    SendReceiptDialog,
    PrinterSelectionDialog,
  },
  data() {
    return {
      displayEmailDialog: false,
      email: "",
      showPrinterDialog: false,
      selectedPrinter: null as any | null,
    };
  },
  computed: {
    ...mapState(["pos"]),
    ...mapGetters({
      getTranId: "pos/getTranId",
      getChangeAmount: "pos/getChangeAmount",
      getUser: "session/getUser",
      getReg: "pos/getRegister",
      getCustomer: "pos/getCustomer",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getPOSRegisterPrinter: "pos/getRegisterPrinter",
      allowMixedMode: "mrkControl/allowMixedMode",
      hostReceiptPrint: "mrkControl/posHostReceiptPrint",
      getDefaultPickTicketPrinter: "pos/getDefaultPickTicketPrinter",
      getPrinterQueues: "printControl/getPrinterQueues",
      getClient: "session/getClient",
      getOrder: "pos/getCurrentOrder",
    }),
  },
  methods: {
    ...mapActions({
      clearCust: "pos/clearCustomer",
      clearOrder: "pos/clearOrder",
      clearTenders: "pos/clearTenders",
      setTranId: "pos/setTranId",
      setChangeAmount: "pos/setChangeAmount",
      addNotification: "notification/add",
      setStoredCartItems: "pos/setStoredCartItems",
      setDefaultPickTicketPrinter: "pos/setDefaultPickTicketPrinter",
    }),
    formatPrice(amount: number) {
      return Utils.formatPrice(amount);
    },
    confirmReceipt() {
      this.clearTenders();
      if (
        !(this.getOrder[0]?.ar_id === "OA" || this.getOrder[0]?.ar_id === "OI")
      ) {
        this.setStoredCartItems({
          items: [],
          type: "orderInvoice",
        });
        this.clearOrder();
      }
      this.setTranId(null);
      this.setChangeAmount(null);
      this.email = "";
      this.$emit("onShowKeepCustomerDialog");
    },
    noReceipt() {
      if (this.getChangeAmount && this.getReg !== null) {
        //call to drawer
        paymentService
          .getDrawerCodes(
            this.getTranId,
            this.getUser.user_id,
            this.getReg?.reg_id,
            true,
          )
          .then((drawerResp: any) => {
            localDesktopService
              .printReceipt(drawerResp, this.getPOSRegisterPrinter)
              .then((response: any) => {
                this.confirmReceipt();
              });
          });
      } else {
        this.confirmReceipt();
      }
    },
    onHide() {
      this.displayEmailDialog = false;
    },
    onSent() {
      this.displayEmailDialog = false;
      this.confirmReceipt();
    },
    downloadReceipt() {
      paymentService
        .getReceipt({ key: this.getTranId, user: this.getUser.user_id })
        .then((response: any) => {
          const bufferArray = Utils.base64ToArrayBuffer(response);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
          this.confirmReceipt();
        })
        .catch((err) => {
          this.addNotification({
            message: `Failed to download receipt.`,
            type: "error",
          });
        });
    },
    async handlePrinterSelection() {
      this.showPrinterDialog = true;
    },
    handleHostReceiptPrint(data: any) {
      this.showPrinterDialog = false;
      // set the default printer if option is selected
      if (data.saveDefaultPrinter) {
        // we may want to support multiple default printers in the future
        this.setDefaultPickTicketPrinter(data.selectedPrinter);
      }
      // Make call for receipt print
      paymentService.getReceipt({
        key: this.getTranId,
        user: this.getUser.user_id,
        reg: this.getReg?.reg_id,
        format: "",
        email: "",
        printer: data.selectedPrinter,
      });
    },

    async printReceipt() {
      // control behavior based on control flag
      if (this.hostReceiptPrint) {
        this.showPrinterDialog = true;
      } else {
        paymentService
          .getReceipt({
            key: this.getTranId,
            user: this.getUser.user_id,
            reg: this.getReg.reg_id,
            email: "",
            format: "escpos",
          })
          .then((response: any) => {
            localDesktopService
              .printReceipt(response, this.getPOSRegisterPrinter)
              .then((response: any) => {
                this.confirmReceipt();
              });
          });
      }
    },
  },
});
