import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid pt-4" }
const _hoisted_2 = { class: "col-6 flex justify-content-start" }
const _hoisted_3 = { class: "col-6 flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PosLineItemsTable = _resolveComponent("PosLineItemsTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.modelValue,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event) => _ctx.$emit('update:modelValue', $event)),
      modal: "",
      draggable: false,
      closable: false,
      class: "cart-dialog pos-cart-dialog",
      style: _normalizeStyle({ width: _ctx.dialogWidth })
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.allowNonStockParts && _ctx.allowAddLineItem)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: "Add Line Item",
                  class: "",
                  onClick: _ctx.addLineItem
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.showSaveButton)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: "Save",
                  class: "",
                  onClick: _ctx.onSaveCartChange
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, {
              label: _ctx.confirmAndCloseText,
              class: "",
              onClick: _ctx.onSaveCartChangeAndClose
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: "Close",
              class: "",
              onClick: _ctx.handleCloseDialog
            }, null, 8, ["onClick"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_PosLineItemsTable, {
            ref: "lineItemsTable",
            value: _ctx.lineItems,
            editable: _ctx.allowEditing,
            editMode: "row",
            class: "cart-parts-table",
            removable: _ctx.allowEditing,
            partNumberLabel: _ctx.partNumberLabel,
            onMostRecentLineItems: _ctx.handleMostRecentLineItems,
            selectedLineItemIndex: _ctx.selectedLineItemIndex,
            lineItemCustomFields: _ctx.lineItemCustomFields,
            compareLineItem: _ctx.compareLineItem
          }, null, 8, ["value", "editable", "removable", "partNumberLabel", "onMostRecentLineItems", "selectedLineItemIndex", "lineItemCustomFields", "compareLineItem"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "style"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showDiscardChangesDialog,
      header: "Unsaved Changes",
      icon: "WARNING",
      message: _ctx.discardChangesMessage,
      primaryButton: "Discard",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.discardChanges,
      onCancelButtonClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDiscardChangesDialog = false))
    }, null, 8, ["showDialog", "message", "onPrimaryButtonClick"])
  ], 64))
}